import { TransactionList } from "./transactions/transaction-list";

export const DashboardPage = () => {
  return (
    <div>
      <div>welcome back</div>
      <TransactionList />
    </div>
  );
};
